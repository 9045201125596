import { SGTM_URL } from '../constants'

const GtagInit = ({ gaId, debug }: { gaId?: string; debug?: boolean }) => {
  if (!gaId) return <></>

  const serverContainerUrl = SGTM_URL(gaId)
  const gtagUrl = serverContainerUrl ?? 'https://www.googletagmanager.com'

  const config =
    ((serverContainerUrl || debug) &&
      `{${[
        serverContainerUrl && `server_container_url: '${serverContainerUrl}'`,
        debug && `debug_mode: true`,
      ]
        .filter(Boolean)
        .join(', \n')}}`) ||
    '{}'

  return (
    <>
      <script async src={`${gtagUrl}/gtag/js?id=${gaId}`}></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer=window.dataLayer||[],window.gtag=function(){dataLayer.push(arguments)},window.gtag("js",new Date),window.gtag("config",'${gaId}', ${config});`,
        }}
      />
    </>
  )
}

export default GtagInit
