import { useWindowScroll } from '@uidotdev/usehooks'
import { useEffect, useState } from 'react'

import { capture } from '../capture.client'

import { SCROLL_LEVELS } from '~/utils/tracking/constants'

const TrackScroll = () => {
  const [scroll] = useWindowScroll()
  const [capturedDepths, setCapturedDepths] = useState<Set<number>>(new Set())

  const scrollY = scroll.y ?? 0

  useEffect(() => {
    if (capturedDepths.size === SCROLL_LEVELS.length) return

    const scrollPercentage = Math.round(
      (scrollY / (document.documentElement.scrollHeight - window.innerHeight)) *
        100,
    )

    for (const depth of SCROLL_LEVELS) {
      if (scrollPercentage >= depth && !capturedDepths.has(depth)) {
        capture({
          eventType: `scroll`,
          eventParams: {
            percent_scrolled: depth,
          },
        })
        setCapturedDepths(new Set(capturedDepths).add(depth))
        break
      }
    }
  }, [scrollY, capturedDepths])

  return null
}

export default TrackScroll
