import { createStorage } from 'unstorage'
import localStorageDriver from 'unstorage/drivers/localstorage'

const storage =
  typeof window !== 'undefined'
    ? createStorage({
        driver: localStorageDriver({
          base: 'energialemon:',
          localStorage: window?.localStorage,
        }),
      })
    : undefined

export default storage
