import storage from '~/utils/storage.utils.client'
import { parseUtms } from '~/utils/utms.utils'

export function updateLastUtms(searchParams: URLSearchParams) {
  const { utm_source, utm_medium, utm_campaign } = parseUtms(searchParams)

  if (utm_source || utm_medium || utm_campaign) {
    storage?.setItem(
      'lastUtms',
      JSON.stringify({ utm_source, utm_medium, utm_campaign }),
    )
  }
}
