import posthog from 'posthog-js'

export const init = (token?: string, userIdentityId?: string) =>
  (token &&
    posthog.init(token, {
      api_host: 'https://us.posthog.com',
      capture_pageview: false,
      capture_pageleave: false,
      bootstrap: {
        isIdentifiedID: false,
        distinctID: userIdentityId,
      },
    })) ||
  undefined

export const PosthogSentryIntegration = (
  organization: string,
  projectId: number,
) => new posthog.SentryIntegration(posthog, organization, projectId)

export const capture = (...args: Parameters<typeof posthog.capture>) =>
  posthog.capture(...args)
