import type { BaseSyntheticEvent } from 'react'

import { capture as posthogCapture } from './posthost.client'

type CaptureProps = {
  eventType: string
  eventParams?: Record<string, any>
  eventBase?: BaseSyntheticEvent
  optOut?: {
    gtag?: boolean
    posthog?: boolean
  }
}

export function captureVisualization(label: string) {
  return capture({ eventType: 'visualization', eventParams: { label } })
}

export function captureClick(label?: string) {
  return (
    eventBase?: BaseSyntheticEvent,
    callback?: (eventBase: BaseSyntheticEvent) => void,
  ) => {
    if (!eventBase || !eventBase.target) {
      capture({
        eventType: 'click',
        eventParams: {
          label: label,
        },
      })
      return
    }
    const captureLabel = label ?? eventBase?.target.innerText
    const rawUrl = eventBase?.target.href ?? eventBase?.target.parentNode.href
    const url =
      typeof URL.canParse === 'function' && URL.canParse(rawUrl)
        ? new URL(rawUrl)
        : undefined

    capture({
      eventType: 'click',
      eventParams: {
        label: captureLabel,
        ...(url?.href && { link_url: url?.href }),
        ...(url?.hostname && { link_domain: url?.hostname }),
      },
    })

    if (callback) callback(eventBase)
  }
}

export function captureInteraction(
  label?: string,
  type?: 'input' | 'select' | 'files',
  result?: string,
) {
  const eventParams = { label, type, result }
  return (
    eventBase?: BaseSyntheticEvent,
    callback?: (eventBase: BaseSyntheticEvent) => void,
  ) => {
    if (!eventBase || !eventBase.target) {
      capture({
        eventType: 'interaction',
        eventParams: eventParams,
      })
      return
    }
    const {
      name: targetName,
      tagName: targetTagName,
      value: targetValue,
      dataset: targetDataset,
      type: targetType,
    } = eventBase?.target

    const allowValueCapture = targetDataset?.allowValueCapture === 'true'

    if (!eventParams.label && targetName) {
      eventParams.label = targetName
    }

    if (['radio', 'checkbox'].includes(targetType)) {
      eventParams.type = `select`
    }

    if (!eventParams.result && allowValueCapture && targetValue) {
      eventParams.result = targetValue
    }

    const interaction_type =
      eventParams.type ?? `${targetTagName}`.toLowerCase()
    const interaction_result = eventParams.result

    capture({
      eventType: 'interaction',
      eventParams: {
        label: eventParams.label,
        ...(interaction_type && { interaction_type }),
        ...(interaction_result && { interaction_result }),
      },
    })

    if (callback) callback(eventBase)
  }
}

export function captureSuccess(label: string) {
  return capture({ eventType: 'success', eventParams: { label } })
}

export function captureFailure(label: string) {
  return capture({ eventType: 'failure', eventParams: { label } })
}

export function capture({
  eventType,
  eventParams = {},
  optOut = {},
}: CaptureProps) {
  if (window?.gtag && !optOut?.gtag) {
    window.gtag('event', eventType, eventParams)
  }

  if (optOut?.posthog) return
  posthogCapture(eventType, eventParams)
}
