import { useEffect } from 'react'

import { init } from '~/utils/tracking/posthost.client'

type InitParameters = Parameters<typeof init>
type Props = {
  token?: InitParameters[0]
  userIdentityId?: InitParameters[1]
}

function PosthogInit({ token, userIdentityId }: Props) {
  useEffect(() => {
    init(token, userIdentityId)
  }, [token, userIdentityId])

  return null
}

export default PosthogInit
