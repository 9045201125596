import { useEffect } from 'react'

import { capture as posthogCapture } from '../posthost.client'

function TrackPageleave() {
  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        posthogCapture('$pageleave')
      }
    }

    window.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange)
    }
  })

  return null
}

export default TrackPageleave
